export const SUBJECTS = {
  CloudDirectories: 'CloudDirectories',
  CloudFiles: 'CloudFiles',
};

export const ACTIONS = {
  create: 'create',
  view: 'view',
  edit: 'edit',
  delete: 'delete',
};
