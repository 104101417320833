<template>
  <div>
    <ez-button
      icon="upload"
      @click="onClick"
      type="primary"
    >
      {{ $t('_upload') }}
    </ez-button>
    <input
      type="file"
      ref="file"
      @change="onChange"
      :accept="getAccept"
      style="display:none"
      multiple
    />
  </div>
</template>

<script>
import EzButton from '@dmant/ez-ui-components/src/components/button';

const FILE_TYPES = {
  all: 'audio/*,image/*,text/*,application/*,.xlsx,.xls,.docs,.doc,.xlsm,.docx,.webm',
  image: 'image/* ',
};

export default {
  name: 'InputFile',
  components: { EzButton },
  props: {
    allowTypes: {
      type: String,
      default: 'all',
    },
  },
  computed: {
    getAccept() {
      return FILE_TYPES[this.allowTypes] || FILE_TYPES.all;
    },
  },
  methods: {
    onChange({ target }) {
      const { files = [] } = target;
      if (files.length) {
        this.$emit('submit', files);
        // eslint-disable-next-line no-param-reassign
        target.value = null; // clear native input
      }
    },
    onClick() {
      this.$refs.file.click();
    },
  },
};
</script>
