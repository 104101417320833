<template>
  <ez-container>
    <ez-row>
      <ez-col>
        <google-map @apply="applyMapData" />
      </ez-col>
    </ez-row>
    <ez-row class="mt-4">
      <ez-col>
        <ez-button-group justify="end">
          <ez-button
            type="primary"
            @click="onSubmit"
          >
            {{ $t('_save_as_image') }}
          </ez-button>
        </ez-button-group>
      </ez-col>
    </ez-row>
  </ez-container>
</template>

<script>
import { URL_API_CLOUD } from '@dmant/ez-env-common';
import Axios from 'axios';
import GoogleMap from './GoogleMap.vue';

const MAP_CONFIG = {
  width: 960,
  height: 400,
  zoom: 12,
  center: { lat: 59.32765876680349, lng: 18.060748142517827 },
  mapOptions: {
    zoomControl: true,
    scaleControl: true,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
  },
  static: 'https://maps.googleapis.com/maps/api/staticmap?',
};

export default {
  name: 'MapGenerator',
  components: {
    GoogleMap,
  },
  created() {},
  data() {
    return {
      data: {
        lat: null,
        lng: null,
        zoom: null,
      },
    };
  },
  methods: {
    applyMapData(data) {
      this.data = {
        lat: data.lat || this.data.lat,
        lng: data.lng || this.data.lng,
        zoom: data.zoom || 12,
      };
    },
    async onSubmit() {
      const key = process.env.VUE_APP_GOOGLE_MAPS_KEY;
      const path = MAP_CONFIG.static;

      const { lat, lng, zoom } = this.data;
      const { width, height } = MAP_CONFIG;
      const params = `center=${lat},${lng}&zoom=${zoom}&size=${width}x${height}&maptype=roadmap&key=${key}`;

      const data = {
        data: {
          link: path + params,
          service: 'cloud',
        },
      };

      try {
        await Axios.post(`${URL_API_CLOUD}/v1/upload_picture_by_link`, data, {
          withCredentials: true,
        });

        this.$emit('apply');
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style>
</style>
