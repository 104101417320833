<template>
  <div>
    <ez-row>
      <ez-col>
        <ez-form-item direction="end">
          <ez-input
            type="search"
            :placeholder="$t('_map_search')"
            :useslot="true"
          >
            <gmap-autocomplete
              :placeholder="$t('_map_search')"
              class="ez-input__control input-search"
              @place_changed="setMap"
              type="search"
            ></gmap-autocomplete>
          </ez-input>
        </ez-form-item>
      </ez-col>
    </ez-row>
    <ez-row class="mt-4">
      <ez-col>
        <gmap-map
          :center="center"
          :options="mapOptions"
          :zoom="zoom"
          @center_changed="boundsChanged"
          @zoom_changed="zoomChanged"
          style="width:100%;  height: 400px;"
        ></gmap-map>
      </ez-col>
    </ez-row>
  </div>
</template>

<script>
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: 'places',
  },
});

export default {
  name: 'googleMap',
  components: {},
  data() {
    return {
      center: { lat: 59.32765876680349, lng: 18.060748142517827 },
      zoom: 12,
      mapOptions: {
        zoomControl: true,
        scaleControl: true,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
      },
    };
  },
  mounted() {
    this.geolocate();
  },
  computed: {
    google: VueGoogleMaps.gmapApi,
  },
  created() {
    this.$emit('apply', this.center);
  },
  methods: {
    geolocate() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.$emit('currentMap', this.center);
        });
      }
    },
    setMap(data) {
      this.center = {
        lat: data.geometry.location.lat(),
        lng: data.geometry.location.lng(),
      };
      this.$emit('currentMap', this.center);
    },
    boundsChanged(data) {
      this.$emit('currentMap', {
        lat: data.lat(),
        lng: data.lng(),
        zoom: this.zoom,
      });
    },
    zoomChanged(data) {
      this.zoom = data;
      this.$emit('currentMap', {
        zoom: this.zoom,
      });
    },
  },
};
</script>
