import { URL_API_CLOUD } from '@dmant/ez-env-common';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import transformer from './transformer';

const Axios = axios.create({
  baseURL: `${URL_API_CLOUD}/v1`,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const FILE_TYPES = {
  image: ['jpeg', 'jpg', 'png', 'gif', 'svg'],
};

Axios.interceptors.response.use((res) => {
  const { data, included = [] } = res.data;
  if (Array.isArray(data)) {
    return data.map((_) => transformer(_, included));
  }
  return transformer(data, included);
}, (error) => Promise.reject(error));

async function getTree(userId) {
  this.tree = await Axios.get(`/directories/tree/?user=${userId}`, {
    withCredentials: true,
  });
}

async function getFiles() {
  this.isLoading = true;
  try {
    const filter = {
      'filter[files.user][and][id][eq]': this.userId,
      'filter[files][and][removed][eq]': 0,
    };

    if (this.folderId) {
      filter['filter[files.directory][and][id][eq]'] = this.folderId;
    } else {
      filter['filter[files][and][directory][isNull]'] = 1;
    }

    if (this.filter.query) {
      filter['filter[files][and][name][like]'] = this.filter.query;
    }

    let ext = this.filter.ext.filter((v) => v.active).map((v) => v.name.replace('.', ''));

    if (this.allowTypes) {
      const types = FILE_TYPES[this.allowTypes];
      ext = ext.length
        ? ext.filter((extension) => types.includes(extension))
        : types;
    }

    if (ext.length) {
      filter['filter[files.source][and][ext][in]'] = ext.join(',');
    }

    const params = {
      service: 'cloud',
      include: 'directory,source',
      sort: this.filter.sortBy.value,
      'page[number]': this.page,
      ...filter,
    };

    const files = await Axios.get('/files', {
      params,
      withCredentials: true,
    });

    if (this.page > 1) {
      this.files = [
        ...this.files,
        ...files,
      ];

      if (!files.length) {
        this.isFinished = true;
      }
    } else {
      this.files = files;
    }
  } catch (e) {
    console.log(e);
  } finally {
    this.isLoading = false;
  }
}

async function processFile(id) {
  try {
    const file = await Axios.get(`/files/${id}`, {
      params: { include: 'source' },
      withCredentials: true,
    });

    if (file && (file.ready || file.removed)) {
      const i = this.process.indexOf(file.id);
      if (i !== -1) {
        this.process.splice(i, 1);
      }
    }

    const index = this.files.findIndex((f) => f.id === file.id);

    if (index > -1) {
      this.files.splice(index, 1, file);
    }
  } catch (e) {
    console.log(e);
  }
}

async function removeFile(id) {
  const file = await Axios.delete(`/files/${id}`, {
    params: { include: 'source' },
    withCredentials: true,
  });
  const index = this.files.findIndex((f) => f.id === file.id);
  if (index > -1) {
    this.files.splice(index, 1);
  }
}

async function renameFile({ id, name }) {
  const file = await Axios.patch(`/files/${id}`, {
    data: {
      type: 'files',
      attributes: {
        name,
        shared: 0,
      },
    },
  }, {
    params: { include: 'source' },
    withCredentials: true,
  });

  const index = this.files.findIndex((f) => f.id === file.id);
  if (index > -1) {
    this.files[index].name = file.name;
  }
}

async function cloneFile(id) {
  const file = await Axios.post(`/files/clone/${id}`, {}, {
    params: { include: 'source' },
    withCredentials: true,
  });
  this.files = [file].concat(this.files);
}

async function getFolders() {
  const filter = {
    'filter[removed][eq]': 0,
  };

  const params = {
    service: 'cloud',
    parent: this.folderId || 'root',
    user: this.userId,
    ...filter,
  };

  this.folders = await Axios.get('/directories/list', {
    params,
    withCredentials: true,
  });
}

async function createFolder({ name, folderId, userId }) {
  const data = {
    data: {
      type: 'directories',
      attributes: {
        name,
        first: false,
      },
    },
    relationships: {},
  };

  if (folderId) {
    data.data.relationships = {
      parent: {
        data: {
          type: 'directories',
          id: folderId,
        },
      },
    };
  }

  if (userId) {
    data.data.relationships = {
      ...data.data.relationships,
      user: {
        data: {
          type: 'user',
          id: userId,
        },
      },
    };
  }

  const folder = await Axios.post('/directories', data);
  this.folders.push(folder);
}

async function removeFolder(id) {
  try {
    const folder = await Axios.delete(`/directories/${id}`, {
      withCredentials: true,
    });
    const indexList = this.folders.findIndex((f) => f.id === folder.id);
    if (indexList > -1) {
      this.folders.splice(indexList, 1);
    }
  } catch (e) {
    console.log(e);
  }
}

async function sendEmailLink({ email, link }) {
  try {
    const data = {
      data: {
        link,
        email,
      },
    };

    return Axios.post('/send_link', data, {
      withCredentials: true,
    });
  } catch (e) {
    return console.log(e);
  }
}

async function moveFolder(params) {
  try {
    const data = {
      data: {
        type: 'directories',
        attributes: {
          first: false,
          last: false,
        },
        relationships: {
          parent: {
            data: {
              type: 'directories',
              id: params.target,
            },
          },
        },
      },
    };

    await Axios.patch(`/directories/${params.folder}`, data, {
      withCredentials: true,
    });
    const index = this.folders.findIndex((f) => f.id === params.folder);
    if (index > -1) {
      this.folders.splice(index, 1);
    }
  } catch (e) {
    console.log(e);
  }
}
async function moveFile({ file, target }) {
  try {
    const data = {
      data: {
        type: 'directories',
        attributes: {
          shared: 0,
        },
        relationships: {
          directory: {
            data: {
              type: 'directories',
              id: target,
            },
          },
        },
      },
    };

    await Axios.patch(`/files/${file}`, data, {
      withCredentials: true,
    });

    const index = this.files.findIndex((f) => f.id === file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  } catch (e) {
    throw new Error(e);
  }
}

async function renameFolder({ id, name }) {
  try {
    const folder = await Axios.patch(`/directories/${id}`, {
      data: {
        type: 'directories',
        attributes: {
          name,
          first: false,
          last: false,
        },
      },
    }, {
      withCredentials: true,
    });

    const indexList = this.folders.findIndex((f) => f.id === folder.id);
    if (indexList > -1) {
      this.folders[indexList].name = folder.name;
    }

    const indexBreadcrumbs = this.breadcrumbs.findIndex((f) => f.value === folder.id);

    if (indexBreadcrumbs > -1) {
      this.breadcrumbs[indexBreadcrumbs].name = folder.name;
    }
    this.folderName = folder.name;
  } catch (e) {
    console.log(e);
  }
}

async function getExtensions() {
  const filter = {
    'filter[files][and][removed][eq]': 0,
    'filter[files.user][and][id][eq]': this.userId,
  };

  if (this.folderId) {
    filter['filter[files.directory][and][id][eq]'] = this.folderId;
  } else {
    filter['filter[files][and][directory][isNull]'] = 1;
  }

  const params = {
    service: 'cloud',
    ...filter,
  };

  const extensions = await Axios.get('/files/ext-list', {
    params,
    withCredentials: true,
  });

  if (this.allowTypes) {
    const types = FILE_TYPES[this.allowTypes];
    this.extensions = extensions.filter(({ ext }) => types.includes(ext));
  } else {
    this.extensions = extensions;
  }
}

async function uploadFile(body) {
  try {
    const data = new FormData();

    data.append('file', body.file);
    data.append('directory', body.directory);
    data.append('service', 'cloud');

    const params = {
      include: 'source,directory',
    };

    const file = await Axios.post('/upload', data, {
      params,
    });
    if (!file.ready) {
      this.process.push(file.id);
    }
    this.files = [file].concat(this.files);
    return file;
  } catch (e) {
    const { data, status } = e.response || {};
    const { errors = '' } = data;
    const errorMessage = Array.isArray(errors) ? errors[0].detail : errors;
    const getMessage = () => {
      switch (status) {
        case 403:
          return 'User not authorized';
        case 400:
          return errorMessage;
        default:
          return 'Failed to upload';
      }
    };
    const reload = ![400].includes(status);
    const file = {
      id: `fail-${uuidv4()}`,
      type: 'fail',
      fileName: body.file.name,
      message: getMessage(),
      reload,
    };
    if (reload) {
      file.meta = {
        file: body.file,
        directory: body.directory,
      };
    }
    this.failFiles = [file].concat(this.failFiles);
  }
  return undefined;
}

export default {
  Axios,
  getFiles,
  processFile,
  removeFile,
  renameFile,
  sendEmailLink,
  cloneFile,
  moveFile,
  getFolders,
  createFolder,
  removeFolder,
  renameFolder,
  moveFolder,
  getExtensions,
  uploadFile,
  getTree,
};
