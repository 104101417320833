<template>
  <div>
    <ez-dialog
      :open="isOpened"
      icon-header="folder"
      @close="onClose"
    >
      <template slot="header">{{ $t('_create_folder_title') }}</template>
      <div class="mt-3">
        <ez-form-item :label="$t('_folder_name')">
          <ez-input
            :placeholder="$t('_folder_name_placehodler')"
            v-model="form.name"
            ref="createInput"
            @keydown.enter.prevent="onSubmit()"
          ></ez-input>
        </ez-form-item>
      </div>
      <ez-button-group justify="end" class="mt-3">
        <ez-button type="secondary" @click="onClose">
          {{ $t('_cancel') }}
        </ez-button>
        <ez-button type="success" @click="onSubmit()">
          {{ $t('_create') }}
        </ez-button>
      </ez-button-group>
    </ez-dialog>
  </div>
</template>

<script>
import EzButton from '@dmant/ez-ui-components/src/components/button';
import EzButtonGroup from '@dmant/ez-ui-components/src/components/button-group';
import EzInput from '@dmant/ez-ui-components/src/components/input';
import EzDialog from '@dmant/ez-ui-components/src/components/dialog';
import EzFormItem from '@dmant/ez-ui-components/src/components/form-item';

export default {
  name: 'NewFolder',
  components: {
    EzButton,
    EzFormItem,
    EzButtonGroup,
    EzInput,
    EzDialog,
  },
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      name: null,
    },
  }),
  watch: {
    isOpened(open) {
      if (open) {
        this.$nextTick(() => this.$refs.createInput.focus());
      }
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.form);
      this.onClose();
    },
    onClose() {
      this.form.name = null;
      this.$emit('close');
    },
  },
};
</script>
