<template>
  <div>
    <ez-card
      draggable="false"
      service-name="cloud"
      :type="data.type"
      :title="data.name"
      style="cursor: pointer"
      @click.stop="handleSelectFolder"
    >
      <template slot="icon">
        <ez-icon name="folder"></ez-icon>
      </template>
      <template slot="title">
        <span v-text="data.name"/>
      </template>
      <template v-if="canEditFolder || canDeleteFolder" slot="dropdown">
        <ez-dropdown direction="right" type="solid">
          <ez-button
            type="text"
            size="xsmall"
            icon="ellipsis-v"
          ></ez-button>
          <ez-dropdown-menu slot="menu">
            <ez-dropdown-item
              v-if="canEditFolder"
              data-test="rename"
              @click.stop="onRename"
              icon="font"
            >
              {{ $t('_rename') }}
            </ez-dropdown-item
            >
            <ez-dropdown-item
              v-if="canEditFolder"
              @click.stop="onMove"
              icon="arrows"
            >
              {{ $t('_move') }}
            </ez-dropdown-item>
            <ez-dropdown-item
              v-if="canDeleteFolder"
              @click="onRemove"
              icon="trash"
              class="color-danger"
            >
              {{ $t('_delete') }}
            </ez-dropdown-item>
          </ez-dropdown-menu>
        </ez-dropdown>
      </template>
    </ez-card>
    <folders-action-dialogs
      ref="dialogs"
      :data="data"
      :user-id="userId"
      :is-pop-up="isPopUp"
      @rename="(data) => $emit('rename', data)"
      @move="(data) => $emit('move', data)"
      @remove="(data) => $emit('remove', data)"
    />
  </div>
</template>

<script>
import EzCard from '@dmant/ez-ui-components/src/components/card';
import EzIcon from '@dmant/ez-ui-components/src/components/icon';
import EzButton from '@dmant/ez-ui-components/src/components/button';
import EzDropdown from '@dmant/ez-ui-components/src/components/dropdown';
import EzDropdownMenu from '@dmant/ez-ui-components/src/components/dropdown-menu';
import EzDropdownItem from '@dmant/ez-ui-components/src/components/dropdown-item';
import FoldersActionDialogs from './FoldersActionDialogs.vue';
import permissionsMixin from '../utils/permissionsMixin';

export default {
  name: 'FolderListItem',
  mixins: [permissionsMixin],
  components: {
    EzCard,
    EzIcon,
    EzButton,
    EzDropdown,
    EzDropdownItem,
    EzDropdownMenu,
    FoldersActionDialogs,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    userId: {
      type: String,
      require: true,
    },
    isPopUp: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleSelectFolder() {
      const { id, name } = this.data;
      this.$emit('handle', {
        id,
        name,
      });
    },
    onRename() {
      this.$refs.dialogs.onToggleRenameDialog();
    },
    onMove() {
      this.$refs.dialogs.onToggleMoveDialog();
    },
    onRemove() {
      this.$refs.dialogs.onToggleRemoveDialog();
    },
  },
};
</script>
