<template>
  <div id="app">
    <template v-if="isLoading" />
    <default-layout
      v-else-if="allowed"
      :user="user"
      @map="showMapGenerator"
      @files="hideMapGenerator"
    >
      <map-generator
        v-if="isMapGenerator"
        @apply="onApplyMapGenerator"
      />
      <cloud-core
        v-else
        :is-pop-up="false"
        :user-id="user['legacy-id']"
      />
    </default-layout>
    <Forbidden v-else />
    <ez-notice />
  </div>
</template>

<script>
import updateAbility from '@dmant/ez-lib/src/ability/update';
import Api from './utils/api';
import CloudCore from '../../../common/ez-cloud-common/src/CloudCore.vue';
import DefaultLayout from './layouts/DefaultLayout.vue';
import MapGenerator from './comonents/MapGenerator.vue';
import authRedirect from './utils/authRedirect';

export default {
  name: 'EzCloudFront',
  components: {
    MapGenerator,
    DefaultLayout,
    CloudCore,
    Forbidden: () => import(/* webpackChunkName: "403" */ './comonents/403.vue'),
  },
  data: () => ({
    user: null,
    isLoading: true,
    isMapGenerator: false,
    allowed: true,
  }),
  async created() {
    try {
      await Api.getUser.call(this);
      this.$i18n.locale = this.user.locale;
      await updateAbility(this.user['legacy-id']);
      this.isLoading = false;
      this.allowed = this.$can('view', 'CloudFiles');
    } catch (e) {
      if (![401, 403].includes(e?.response?.status)) {
        throw e;
      }
      authRedirect();
    }
  },
  methods: {
    showMapGenerator() {
      this.isMapGenerator = true;
    },
    hideMapGenerator() {
      this.isMapGenerator = false;
    },
    onApplyMapGenerator() {
      this.hideMapGenerator();
    },
    notifyLoggedIn() {
      let displayName = this.user.name;
      if (!displayName && this.user) {
        const firstName = this.user['first-name'] || '';
        const lastName = this.user['last-name'] || '';
        displayName = `${firstName} ${lastName}`.trim();
      }
      if (displayName) {
        const content = this.$t('Logged in as', { name: displayName });
        this.$notify({
          data: {
            type: 'cloud',
            content,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

@import '~@dmant/ez-ui-components/src/components/core/src/core';
</style>
