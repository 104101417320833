/* eslint-disable no-param-reassign */
export default (data = {}, included) => {
  let result = {};
  if (data.attributes) {
    result = { ...data.attributes };
    const { relationships = { data: [] } } = data;

    if (data && data.id && data.attributes) {
      data.included = {};
      data.attributes = {
        id: data.id,
        ...data.attributes,
      };
    }

    Object.keys(relationships).forEach((key) => {
      const relations = [].concat(relationships[key].data || []);
      const ids = relations.map(({ id }) => id);
      included.forEach((include) => {
        if (ids.find((_) => _ === include.id)) {
          result[include.type] = include.attributes;
        }
      });
    });
  } else {
    result = { ...data };
  }

  return result;
};
