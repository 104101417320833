import * as LOCALES from '../utils/locale';
import common from './common';

const translations = {
  ...common,
};

const LANGUAGES = [LOCALES.DE, LOCALES.EN];

export function generate(data) {
  const messages = {};

  LANGUAGES.forEach((lang) => {
    messages[lang] = {};
  });

  LANGUAGES.forEach((lang) => {
    Object.keys(data).forEach((key) => {
      const locale = LOCALES.LOCALES_MAP[lang];
      if (data[key][locale]) {
        messages[lang][key] = data[key][locale] || key;
      }
    });
  });

  return messages;
}

export default generate(translations);
