// eslint-disable-next-line import/prefer-default-export
export const SORT = [
  {
    name: 'name',
    value: 'name',
  },
  {
    name: '-name',
    value: '-name',
  },
  {
    name: 'dt-create',
    value: 'dt-create',
  },
  {
    name: '-dt-create',
    value: '-dt-create',
  },
];
