import { URL_API_USER, URL_API_CLOUD } from '@dmant/ez-env-common';
import axios from 'axios';

const Axios = axios.create({
  baseURL: `${URL_API_USER}/v1`,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

async function getUser() {
  const result = await Axios.get('/identity');
  const { attributes } = result.data.data;
  this.user = {
    ...attributes,
    avatarUrl: await getAvatarUrl(attributes.avatar) || '',
  };
}

async function logout() {
  await Axios.get('/logout');
}

async function getAvatarUrl(cloudId) {
  if (cloudId) {
    try {
      const { data } = await Axios.get(`${URL_API_CLOUD}/v1/files/${cloudId}`);
      return data?.data?.attributes?.url;
    } catch (error) {
      console.log(error);
    }
  }
  return undefined;
}

export default {
  getUser,
  logout,
};
