<template>
  <ez-dialog
    open
    :icon-header="icon"
    @close="onCancel"
  >
    <template slot="header">
      {{ title }}
    </template>

    <slot />
    <ez-button-group justify="end" class="mt-3">
      <ez-button type="secondary" @click="onCancel">
        {{ $t('_cancel') }}
      </ez-button>
      <ez-button :type="confirmButtonType" @click="onConfirm()">
        {{ $t(confirmButtonLabel) }}
      </ez-button>
    </ez-button-group>
  </ez-dialog>
</template>

<script>
import EzDialog from '@dmant/ez-ui-components/src/components/dialog';
import EzButton from '@dmant/ez-ui-components/src/components/button';
import EzButtonGroup from '@dmant/ez-ui-components/src/components/button-group';

export default {
  name: 'ActionDialog',
  components: { EzButton, EzButtonGroup, EzDialog },
  props: {
    icon: {
      type: String,
      default: 'folder',
    },
    title: {
      type: String,
      default: null,
    },
    confirmButtonLabel: {
      type: String,
      default: '_create',
    },
    confirmButtonType: {
      type: String,
      default: 'success',
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onConfirm() {
      this.$emit('confirm');
    },
  },
};
</script>
