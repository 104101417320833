export default {
  _start_breadcrumb: {
    en: 'My Cloud',
    de: 'Meine Ablage',
  },
  _add_folder: {
    en: 'Add Folder',
    de: 'Neuer Ordner',
  },
  _placeholder_search_file: {
    en: 'Search file',
    de: 'Datei suchen',
  },
  _file_types: {
    en: 'File types',
    de: 'Dateitypen',
  },
  _sort: {
    en: 'Sort',
    de: 'Sortieren',
  },
  _cancel: {
    en: 'Cancel',
    de: 'Abbrechen',
  },
  _apply: {
    en: 'Apply',
    de: 'Anwenden',
  },
  _sort_types_name: {
    en: 'Sort by name (ascending)',
    de: 'Nach Name sortieren (aufsteigend)',
  },
  _sort_types__name: {
    en: 'Sort by name (descending)',
    de: 'Nach Name sortieren (absteigend)',
  },
  _sort_types_dt_create: {
    en: 'Sort by date (ascending)',
    de: 'Nach Datum sortieren (aufsteigend)',
  },
  _sort_types__dt_create: {
    en: 'Sort by date (descending)',
    de: 'Nach Datum sortieren (absteigend)',
  },
  _add_file: {
    en: 'Add file',
    de: 'Datei hinzufügen',
  },
  _files: {
    en: 'Files',
    de: 'Dateien',
  },
  _confirm: {
    en: 'Confirm',
    de: 'Bestätigen',
  },
  _no_files: {
    en: 'No files',
    de: 'Keine Dateien',
  },
  _folders: {
    en: 'Folders',
    de: 'Ordner',
  },
  _no_folders: {
    en: 'No folders',
    de: 'Keine Ordner',
  },
  _rename: {
    en: 'Rename',
    de: 'Umbenennen',
  },
  _move: {
    en: 'Move',
    de: 'Verschieben',
  },
  _delete: {
    en: 'Delete',
    de: 'Löschen',
  },
  _renama_folder: {
    en: 'Rename folder',
    de: 'Ordner umbenennen',
  },
  _rename_folder_placeholder: {
    en: 'New folder name',
    de: 'Neuer Ordnername',
  },
  _move_folder: {
    en: 'Move folder',
    de: 'Ordner verschieben',
  },
  _move_file: {
    en: 'Move file',
    de: 'Datei verschieben',
  },
  _delete_folder: {
    en: 'Delete folder',
    de: 'Ordner löschen',
  },
  _view: {
    en: 'View',
    de: 'Vorschau',
  },
  _clone: {
    en: 'Clone',
    de: 'Klonen',
  },
  _download: {
    en: 'Download',
    de: 'Herunterladen',
  },
  _rename_file: {
    en: 'Rename file',
    de: 'Datei umbenennen',
  },
  _get_share_link: {
    en: 'Get shareable link',
    de: 'Teilen',
  },
  _copyLink: {
    en: 'Copy link on the file',
    de: 'Link zur Datei',
  },
  _copyLinkButton: {
    en: 'Copy link',
    de: 'Link kopieren',
  },
  _delete_file: {
    en: 'Delete File',
    de: 'Datei löschen',
  },
  _rename_file_placeholder: {
    en: 'Enter filename',
    de: 'Dateinamen eingeben',
  },
  _delete_file_desc: {
    en: 'Are you sure you want to delete <strong>{file}</strong> file?',
    de: 'Bist du sicher, dass du {file} löschen möchtest?',
  },
  _delete_folder_desc: {
    en: 'Are you sure you want to delete <strong>{folder}</strong> folder?',
    de: 'Bist du sicher, dass du {folder} löschen möchtest?',
  },
  _upload: {
    en: 'Upload',
    de: 'Hochladen',
  },
  _create: {
    en: 'Create',
    de: 'Erstellen',
  },
  _explorer_all: {
    en: 'All',
    de: 'Alle',
  },
  _new_file_name: {
    en: 'New file name',
    de: 'Neuer Dateiname',
  },
  _create_folder_title: {
    en: 'Create folder',
    de: 'Ordner erstellen',
  },
  _folder_name: {
    en: 'Folder name',
    de: 'Ordnernamen',
  },
  _rename_folder: {
    en: 'Rename folder',
    de: 'Ordner umbenennen',
  },
  _folder_name_placehodler: {
    en: 'Input folder name',
    de: 'Ordnernamen eingeben',
  },
  _empty_title: {
    en: 'This folder is empty!',
    de: 'Dieser Ordner ist leer!',
  },
  _empty_desc: {
    en: 'Upload files via drag&drop or click on the button below',
    de: 'Lade Dateien via Drag&Drop hoch, oder klicke auf den unteren Button',
  },
  _choose_file: {
    en: 'Choose a file to add',
    de: 'Wählen Sie eine Datei zum Hinzufügen',
  },
  _drop_files_here: {
    en: 'Drop files here',
    de: 'Legen Sie hier Dateien ab',
  },
  _or_send_on_email: {
    en: 'or send link on email',
    de: 'oder den Link per E-Mail senden',
  },
  _send: {
    en: 'Send',
    de: 'Senden',
  },
  _link_copied: {
    en: 'Link is copied to clipboard',
    de: 'Link wird in die Zwischenablage kopiert',
  },
  _send_email_placeholder: {
    en: 'Enter email adress',
    de: 'E-Mail Adresse eingeben',
  },
  _invalid_email: {
    en: 'It is no valid email',
    de: 'Es ist keine gültige E-Mail',
  },
  _dismiss: {
    en: 'Dismiss',
    de: 'Entlassen',
  },
  _reload: {
    en: 'Reload',
    de: 'Neu laden',
  },
  'User not authorized': {
    en: 'User not authorized',
    de: 'Benutzer nicht autorisiert',
  },
  'Failed to upload': {
    en: 'Failed to upload',
    de: 'Upload fehlgeschlagen',
  },
  'File could not be uploaded': {
    en: 'File could not be uploaded',
    de: 'Datei konnte nicht hochgeladen werden',
  },
  'empty file is disallow': {
    en: 'Empty file is disallow',
    de: 'Leere Datei ist nicht erlaubt',
  },
  'File is not allowed': {
    en: 'File is not allowed',
    de: 'Datei ist nicht erlaubt',
  },
};
