import {
  ACTIONS,
  SUBJECTS,
} from './permissions';

export default {
  computed: {
    canCreateFolder() {
      return this.$can(ACTIONS.create, SUBJECTS.CloudDirectories);
    },
    canEditFolder() {
      return this.$can(ACTIONS.edit, SUBJECTS.CloudDirectories);
    },
    canDeleteFolder() {
      return this.$can(ACTIONS.delete, SUBJECTS.CloudDirectories);
    },
    canActionFolder() {
      return this.canCreateFolder || this.canEditFolder || this.canDeleteFolder;
    },
    canCreateFile() {
      return this.$can(ACTIONS.create, SUBJECTS.CloudFiles);
    },
    canEditFile() {
      return this.$can(ACTIONS.edit, SUBJECTS.CloudFiles);
    },
    canDeleteFile() {
      return this.$can(ACTIONS.delete, SUBJECTS.CloudFiles);
    },
  },
};
