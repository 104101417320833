<template>
  <ez-header
    service-name="cloud"
    service-id="cloud"
    @logoClick="onLogoClick"
  >
    <template slot="user">
      <ez-dropdown-user
        :options="managersListOptions"
        :selected="user.id"
        @user-selected="onUserSelect"
        @logout="onLogout"
      />
    </template>
  </ez-header>
</template>

<script>
import Api from '../../utils/api';
import authRedirect from '../../utils/authRedirect';

export default {
  name: 'DefautlHeader',
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    managersListOptions() {
      const {
        id,
        email,
        'first-name': firstName,
        'last-name': lastName,
        avatarUrl,
      } = this.user;

      return [{
        id,
        email,
        name: `${firstName} ${lastName}`,
        image: avatarUrl,
      }];
    },
  },
  methods: {
    onLogoClick() {
      window.location.href = '/';
    },
    async onLogout() {
      try {
        await Api.logout();
        authRedirect('');
      } catch (e) {
        console.log(e);
      }
    },
    onUserSelect() {

    },
  },
};
</script>
