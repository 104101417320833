<template>
  <div class="cards__list">
    <div class="cards__list-title">{{ $t('_files') }}</div>
    <ez-row v-if="data.length > 0" gutter="20">
      <ez-col
        v-if="canCreateFile"
        :size="[{ sm: 12 }, { md: 6 }, { lg: 4 }]"
      >
        <div>
          <ez-card type="add" @click="$emit('open')">
            <template slot="preview">
              <img src alt/>
            </template>
            <template slot="icon">
              <ez-icon name="'Add file'"></ez-icon>
            </template>
            <template slot="title">{{ $t('_add_file') }}</template>
          </ez-card>
        </div>
      </ez-col>
      <ez-col
        :size="[{ sm: 12 }, { md: 6 }, { lg: 4 }]"
        v-for="file in fails"
        :key="file.id"
      >
        <files-list-item
          :data="file"
          @dismiss="onDismiss"
          @reload="onReload"
        />
      </ez-col>
      <ez-col
        :size="[{ sm: 12 }, { md: 6 }, { lg: 4 }]"
        v-for="file in data"
        :key="file.id"
      >
        <files-list-item
          :data="file"
          :file-id="fileId"
          :folder-id="folderId"
          :is-pop-up="isPopUp"
          :user-id="userId"
          @handle="onHandle"
          @remove="onRemove"
          @rename="onRename"
          @clone="onClone"
          @move="onMove"
          @send="onSend"
        />
      </ez-col>
    </ez-row>
    <div v-else>{{ $t('_no_files') }}</div>
  </div>
</template>

<script>
import EzCard from '@dmant/ez-ui-components/src/components/card';
import EzCol from '@dmant/ez-ui-components/src/components/col';
import EzRow from '@dmant/ez-ui-components/src/components/row';
import FilesListItem from './FilesListItem.vue';
import permissionsMixin from '../utils/permissionsMixin';

export default {
  name: 'FilesList',
  mixins: [permissionsMixin],
  components: {
    FilesListItem, EzCard, EzCol, EzRow,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    fails: {
      type: Array,
      default: () => [],
    },
    fileId: {
      type: String,
      default: null,
    },
    folderId: {
      type: String,
      default: null,
    },
    isPopUp: {
      type: Boolean,
      default: true,
    },
    userId: {
      type: String,
      require: true,
    },
  },
  methods: {
    onHandle(id) {
      this.$emit('handle', id);
    },
    onRemove(id) {
      this.$emit('remove', id);
    },
    onRename(id) {
      this.$emit('rename', id);
    },
    onClone(id) {
      this.$emit('clone', id);
    },
    onDismiss(id) {
      this.$emit('dismiss', id);
    },
    onReload(id) {
      this.$emit('reload', id);
    },
    onMove(data) {
      this.$emit('move', data);
    },
    onSend(data) {
      this.$emit('send', data);
    },
  },
};
</script>

<style lang="scss">
.cards {
  &__list {
    margin-bottom: 40px;

    &-title {
      font-weight: 500;
      margin-bottom: 16px;
      font-size: 14px;
      color: #777777;
    }
  }
}
</style>
