<template>
  <ez-layout :showQuickStartPanel="showQuickStartPanel" name="cloud">
    <default-header
      :user="user"
      @files="$emit('files')"
      @map="$emit('map')"
    />

    <ez-content background>
      <ez-container>
        <slot />
      </ez-container>
    </ez-content>

    <default-footer :user="user" />
    <template #quick>
      <QuickStartVideo />
    </template>
  </ez-layout>
</template>

<script>
import { QuickStartVideo } from '@dmant/ez-lib/src/quick-start-video';
import DefaultFooter from './components/DefaultFooter.vue';
import DefaultHeader from './components/DefaultHeader.vue';

export default {
  name: 'DefaultLayout',
  components: {
    DefaultHeader,
    DefaultFooter,
    QuickStartVideo,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    showQuickStartPanel() {
      return this.$store.getters[`${this.$quickStartVideo.moduleName}/show`];
    },
  },
};
</script>
