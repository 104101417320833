import Vue from 'vue';
import { refreshToken } from '@dmant/ez-auth-common';
import './plugins/sentry';
import Ability from '@dmant/ez-lib/src/ability/install';
import QuickStartVideoPlugin from '@dmant/ez-lib/src/quick-start-video';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import './components';

Vue.use(Ability);
Vue.use(QuickStartVideoPlugin, { router, store });
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

refreshToken();
/* eslint-disable no-console */
console.log(`Build: ${BUILD_INFO.BUILDDATETIME}, ${BUILD_INFO.VERSION}, ${BUILD_INFO.COMMITHASH}, ${BUILD_INFO.BRANCH}`);
