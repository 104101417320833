import Vue from 'vue';

import Icon from '@dmant/ez-ui-components/src/components/icon';
import Icons from '@dmant/ez-ui-components/src/components/icons';
import Select from '@dmant/ez-ui-components/src/components/select';
import Layout from '@dmant/ez-ui-components/src/components/layout';
import Header from '@dmant/ez-ui-components/src/components/header';
import HeaderBar from '@dmant/ez-ui-components/src/components/header-bar';
import Container from '@dmant/ez-ui-components/src/components/container';
import Row from '@dmant/ez-ui-components/src/components/row';
import Col from '@dmant/ez-ui-components/src/components/col';
import Link from '@dmant/ez-ui-components/src/components/link';
import Logotype from '@dmant/ez-ui-components/src/components/logotype';
import Button from '@dmant/ez-ui-components/src/components/button';
import ButtonGroup from '@dmant/ez-ui-components/src/components/button-group';
import Footer from '@dmant/ez-ui-components/src/components/footer';
import Panel from '@dmant/ez-ui-components/src/components/panel';
import Content from '@dmant/ez-ui-components/src/components/content';
import Input from '@dmant/ez-ui-components/src/components/input';
import Tabs from '@dmant/ez-ui-components/src/components/tabs';
import SubHeader from '@dmant/ez-ui-components/src/components/sub-header';
import Dropdown from '@dmant/ez-ui-components/src/components/dropdown';
import DropdownUser from '@dmant/ez-ui-components/src/components/dropdown-user';
import Form from '@dmant/ez-ui-components/src/components/form';
import FormItem from '@dmant/ez-ui-components/src/components/form-item';
import Collapse from '@dmant/ez-ui-components/src/components/collapse';
import Dialog from '@dmant/ez-ui-components/src/components/dialog';
import Switch from '@dmant/ez-ui-components/src/components/switch';
import Box from '@dmant/ez-ui-components/src/components/box';
import Textarea from '@dmant/ez-ui-components/src/components/textarea';
import ColorPicker from '@dmant/ez-ui-components/src/components/color-picker';
import ButtonsSwitch from '@dmant/ez-ui-components/src/components/buttons-switch';
import Steps from '@dmant/ez-ui-components/src/components/steps';
import FormGroup from '@dmant/ez-ui-components/src/components/form-group';
import Notification from '@dmant/ez-ui-components/src/components/notification';
import Notice from '@dmant/ez-ui-components/src/components/notice';
import FilterExport from '@dmant/ez-ui-components/src/components/filter-export';
import Checkbox from '@dmant/ez-ui-components/src/components/checkbox';
import Radio from '@dmant/ez-ui-components/src/components/radio';
import DropdownItem from '@dmant/ez-ui-components/src/components/dropdown-item';
import DropdownMenu from '@dmant/ez-ui-components/src/components/dropdown-menu';
import Pagination from '@dmant/ez-ui-components/src/components/pagination';
import RichText from '@dmant/ez-ui-components/src/components/rich-text';
import Preloader from '@dmant/ez-ui-components/src/components/preloader';
import DatePicker from '@dmant/ez-ui-components/src/components/date-picker';
import PopupContainer from '@dmant/ez-ui-components/src/components/popup-container';
import Breadcrumbs from '@dmant/ez-ui-components/src/components/breadcrumbs';
import Card from '@dmant/ez-ui-components/src/components/card';
import DropArea from '@dmant/ez-ui-components/src/components/drop-area';
import FilterControl from '@dmant/ez-ui-components/src/components/filter-control';
import Message from '@dmant/ez-ui-components/src/components/message';
import FlashMessage from '@dmant/ez-ui-components/src/components/flash-message';
import InfoBox from '@dmant/ez-ui-components/src/components/info-box';

const components = [
  Icon,
  Icons,
  Select,
  Layout,
  Header,
  HeaderBar,
  Container,
  Row,
  Col,
  Link,
  Logotype,
  Button,
  ButtonGroup,
  Footer,
  Panel,
  Content,
  Input,
  Tabs,
  SubHeader,
  Dropdown,
  DropdownUser,
  Form,
  FormItem,
  Collapse,
  Dialog,
  Switch,
  Box,
  Textarea,
  ColorPicker,
  ButtonsSwitch,
  Steps,
  FormGroup,
  Notification,
  Notice,
  Icon,
  FilterExport,
  Checkbox,
  Radio,
  DropdownItem,
  DropdownMenu,
  Pagination,
  RichText,
  Preloader,
  DatePicker,
  PopupContainer,
  Breadcrumbs,
  Card,
  DropArea,
  FilterControl,
  Message,
  FlashMessage,
  InfoBox,
];

components.forEach((component) => {
  Vue.use(component);
});
