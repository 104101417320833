<template>
  <div>
    <action-dialog
      v-if="canDeleteFolder && isOpenRemoveDialog"
      :title="$t('_delete_folder')"
      :confirm-button-label="$t('_delete')"
      confirm-button-type="danger"

      @confirm="onConfirmRemove"
      @cancel="onToggleRemoveDialog"
    >
      <p v-html="$t('_delete_folder_desc', { folder: data.name })"/>
    </action-dialog>

    <action-dialog
      v-if="canEditFolder && isOpenRenameDialog"
      :title="$t('_rename_folder')"
      :confirm-button-label="$t('_rename')"
      @confirm="onConfirmRename"
      @cancel="onToggleRenameDialog"
    >
      <ez-input
        :placeholder="$t('_rename_folder_placeholder')"
        v-model="form.name"
        ref="input"
        @keydown.enter.prevent="onConfirmRename()"
      />
    </action-dialog>

    <action-dialog
      v-if="canEditFolder && isOpenMoveDialog"
      :title="$t('_move_folder')"
      :confirm-button-label="$t('_move')"
      @confirm="onConfirmMove"
      @cancel="onToggleMoveDialog"
    >
      <ez-tree
        :data="treeOptions"
        @select="onSelectMoveFolder"
      />
    </action-dialog>
  </div>
</template>

<script>
import EzInput from '@dmant/ez-ui-components/src/components/input';
import EzTree from '@dmant/ez-ui-components/src/components/tree';
import ActionDialog from './ActionDialog.vue';
import treeOptions from '../utils/treeOptions';
import Api from '../utils/api';
import permissionsMixin from '../utils/permissionsMixin';

export default {
  name: 'FoldersActionDialogs',
  mixins: [permissionsMixin],
  components: {
    ActionDialog,
    EzInput,
    EzTree,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    userId: {
      type: String,
      require: true,
    },
    isPopUp: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isOpenRemoveDialog: false,
    isOpenRenameDialog: false,
    isOpenMoveDialog: false,
    moveForlderTarget: null,
    tree: [],
    breadcrumbs: null,
    moveCrumbs: [],
    form: {
      name: null,
      target: null,
    },
  }),
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (value) {
          this.form.name = value.name;
        }
      },
    },
  },
  computed: {
    treeOptions,
  },
  methods: {
    onToggleRemoveDialog() {
      this.isOpenRemoveDialog = !this.isOpenRemoveDialog;
    },
    onConfirmRemove() {
      this.$emit('remove', this.data.id);
      this.onToggleRemoveDialog();
    },
    // Rename
    onToggleRenameDialog() {
      this.isOpenRenameDialog = !this.isOpenRenameDialog;
      if (this.isOpenRenameDialog) {
        this.$nextTick(() => this.$refs.input.focus());
      }
    },
    onConfirmRename() {
      if (this.form.name !== this.data.name) {
        this.$emit('rename', {
          id: this.data.id,
          name: this.form.name,
        });
      }

      this.onToggleRenameDialog();
    },
    // Move
    async onToggleMoveDialog() {
      await Api.getTree.call(this, this.userId);
      this.isOpenMoveDialog = !this.isOpenMoveDialog;
    },
    async onSelectMoveFolder(data) {
      const { id, action } = data;
      this.moveCrumbs = [];
      this.calcMoveCrumbs(id);
      this.moveForlderTarget = action ? id : null;
    },
    calcMoveCrumbs(folderId) {
      const { id, name, parentDirectory } = this.tree.find((_) => _.id === folderId);
      this.moveCrumbs.push({
        icon: 'folder',
        name,
        value: id,
        width: name.length,
      });

      if (parentDirectory) {
        this.calcMoveCrumbs(parentDirectory);
      } else {
        this.moveCrumbs = [this.data].concat(this.moveCrumbs);
      }
    },
    onConfirmMove() {
      this.$emit('move', {
        folder: this.data.id,
        target: this.moveForlderTarget,
        moveCrumbs: this.moveCrumbs,
      });

      this.onToggleMoveDialog();
    },
  },
};
</script>
