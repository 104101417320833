import arrayToTree from 'array-to-tree';

export default function treeOptions() {
  let tree = [...this.tree];

  tree.forEach((f, i) => {
    tree[i].parent_id = f.parentDirectory;
    tree[i].label = f.name;
    tree[i].open = false;
    tree[i].disabled = f.id === this.folderId;
  });

  tree = tree
    .filter((f) => f.id !== this.data.id);

  let nodes = arrayToTree(tree, {
    childrenProperty: 'nodes',
  });

  nodes = nodes.filter((f) => f.parent_id === null);

  return {
    id: null,
    label: 'ezCloud',
    open: true,
    nodes,
    disabled: this.folderId === null,
  };
}
