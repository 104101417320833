<template>
  <div class="cards__list">
    <div class="cards__list-title">{{ $t('_folders') }}</div>
    <ez-row v-if="data.length > 0" gutter="20">
      <ez-col
        :size="[{ sm: 12 }, { md: 6 }, { lg: 4 }]"
        v-for="(folder, index) in data"
        :key="index"
      >
        <folders-list-item
          :key="folder.id"
          :data="folder"
          :is-pop-up="isPopUp"
          :user-id="userId"
          ref="folder"
          @handle="onHandle"
          @remove="onRemove"
          @rename="onRename"
          @move="onMove"
        />
      </ez-col>
    </ez-row>
    <div v-else>{{ $t('_no_folders') }}</div>
  </div>
</template>

<script>
import EzCol from '@dmant/ez-ui-components/src/components/col';
import EzRow from '@dmant/ez-ui-components/src/components/row';
import FoldersListItem from './FoldersListItem.vue';

export default {
  name: 'FolderList',
  components: { FoldersListItem, EzCol, EzRow },
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
    isPopUp: {
      type: Boolean,
      default: true,
    },
    userId: {
      type: String,
      require: true,
    },
  },
  methods: {
    onHandle(data) {
      this.$emit('handle', data);
    },
    onRemove(id) {
      this.$emit('remove', id);
    },
    onRename(data) {
      this.$emit('rename', data);
    },
    onMove(data) {
      this.$emit('move', data);
    },
  },
};
</script>

<style lang="scss">
.cards {
  &__list {
    margin-bottom: 40px;

    &-title {
      font-weight: 500;
      margin-bottom: 16px;
      font-size: 14px;
      color: #777777;
    }
  }
}
</style>
