<template>
  <div>
    <ez-card
      v-if="data.type === 'fail'"
      :type="data.type"
    >
      <template slot="title">{{data.fileName}}</template>
      <template slot="message">{{$t(data.message)}}</template>
      <template slot="button">
        <ez-button
          v-if="data.reload"
          type="secondary"
          size="small"
          block
          v-t="$t('_reload')"
          @click="onReload"
        />
        <ez-button
          v-else
          type="secondary"
          size="small"
          block
          v-t="$t('_dismiss')"
          @click="onDismiss"
        />
      </template>
    </ez-card>
    <ez-card
      v-else
      draggable="false"
      service-name="cloud"
      :type="data.type"
      :title="data.name"
      :selected="isSelected"
      :progress="isProgressPreview"
      icon="file"
      :preview="preview"
      @click.stop="onHandle"
    >
      <template slot="title">
        <span v-text="name"/>
      </template>
      <template slot="preview" v-if="preview">
        <img draggable="false" :src="preview" alt/>
      </template>

      <template slot="dropdown">
        <ez-dropdown direction="right" type="solid">
          <ez-button
            type="text"
            size="xsmall"
            icon="ellipsis-v"
          />
          <ez-dropdown-menu slot="menu">
            <ez-dropdown-item
              @click="onView"
              icon="eye"
            >
              {{ $t('_view') }}
            </ez-dropdown-item>
            <ez-dropdown-item
              v-if="canEditFile"
              @click="onToggleRenameDialog"
              icon="edit"
            >
              {{ $t('_rename') }}
            </ez-dropdown-item>
            <ez-dropdown-item
              @click="onToggleShareDialog"
              icon="link"
              divided
            >
              {{ $t('_get_share_link') }}
            </ez-dropdown-item>

            <ez-dropdown-item
              v-if="canEditFile"
              @click.stop="onToggleMoveDialog"
              icon="arrows"
            >
              {{ $t('_move') }}
            </ez-dropdown-item>

            <ez-dropdown-item
              v-if="canCreateFile"
              @click="onClone"
              icon="clone"
            >
              {{ $t('_clone') }}
            </ez-dropdown-item>
            <ez-dropdown-item
              @click="onDownload"
              icon="download"
              divided
            >
              {{ $t('_download') }}
            </ez-dropdown-item>
            <ez-dropdown-item
              v-if="canDeleteFile"
              @click="onToggleRemoveDialog"
              icon="trash"
              data-test="delete"
              class="color-danger"
            >
              {{ $t('_delete') }}
            </ez-dropdown-item>
          </ez-dropdown-menu>
        </ez-dropdown>
      </template>
    </ez-card>

    <action-dialog
      v-if="canDeleteFile && isOpenRemoveDialog"
      :title="$t('_delete_file')"
      :confirm-button-label="$t('_delete')"
      confirm-button-type="danger"
      icon="file"
      @confirm="onConfirmRemove"
      @cancel="onToggleRemoveDialog"
    >
      <p v-html="$t('_delete_file_desc', { file: data.name })" />
    </action-dialog>

    <action-dialog
      v-if="canEditFile && isOpenRenameDialog"
      :title="$t('_rename_file')"
      :confirm-button-label="$t('_rename')"
      icon="font"
      @confirm="onConfirmRename"
      @cancel="onToggleRenameDialog"
    >
      <ez-form>
        <ez-form-item :label="$t('_new_file_name')">
          <ez-input
            :placeholder="$t('_rename_file_placeholder')"
            v-model="form.name"
            @keydown.enter.prevent="onConfirmRename()"
          />
        </ez-form-item>
      </ez-form>
    </action-dialog>

    <action-dialog
      v-if="isOpenShareDialog"
      :title="$t('_get_share_link')"
      :confirm-button-label="$t('_send')"
      icon="link"
      @confirm="onConfirmSend"
      @cancel="onToggleShareDialog"
    >
      <ez-form>
        <ez-form-item
          :label="`${ $t('_copyLink') }: ${data.name}.${data.source && data.source.ext}`"
          solid
        >
          <ez-input
            ref="link"
            @keydown.enter.prevent
            :value="data.url"
            readonly="readonly"
          ></ez-input>
          <ez-button @click="onCopyShareLink" type="secondary">
            {{ $t('_copyLinkButton') }}
          </ez-button>
        </ez-form-item>

        <ez-form-item solid>
          <div
            v-if="isCopied"
            style="color: #26C281;font-size: 13px; margin-top: -10px"
          >
            {{ $t('_link_copied') }}
          </div>
        </ez-form-item>

        <ez-form-item
          :label="$t('_or_send_on_email')"
          :type="isInvalidEmail ? 'error' : ''"
          :message="isInvalidEmail ? $t('_invalid_email') : null"
        >
          <ez-input
            v-model="form.email"
            :placeholder="$t('_send_email_placeholder')"
          />
        </ez-form-item>
      </ez-form>
    </action-dialog>

    <action-dialog
      v-if="canEditFile && isOpenMoveDialog"
      ref="rename"
      icon="file"
      :title="$t('_move_file')"
      :confirm-button-label="$t('_move')"
      @confirm="onConfirmMove"
      @cancel="onToggleMoveDialog"
    >
      <ez-tree
        :data="treeOptions"
        @select="onSelectMoveFolder"
      />
    </action-dialog>

  </div>
</template>

<script>
import EzCard from '@dmant/ez-ui-components/src/components/card';
import EzForm from '@dmant/ez-ui-components/src/components/form';
import EzInput from '@dmant/ez-ui-components/src/components/input';
import EzFormItem from '@dmant/ez-ui-components/src/components/form-item';
import EzDropdown from '@dmant/ez-ui-components/src/components/dropdown';
import EzDropdownMenu from '@dmant/ez-ui-components/src/components/dropdown-menu';
import EzDropdownItem from '@dmant/ez-ui-components/src/components/dropdown-item';
import EzButton from '@dmant/ez-ui-components/src/components/button';
import EzTree from '@dmant/ez-ui-components/src/components/tree';
import ActionDialog from './ActionDialog.vue';
import treeOptions from '../utils/treeOptions';
import Api from '../utils/api';
import permissionsMixin from '../utils/permissionsMixin';

const EXT_IMAGE = [
  'png',
  'jpg',
  'jpeg',
  'gif',
  'ico',
  'svg',
];

export default {
  name: 'FilesListItem',
  mixins: [permissionsMixin],
  components: {
    ActionDialog,
    EzCard,
    EzForm,
    EzDropdown,
    EzDropdownMenu,
    EzDropdownItem,
    EzInput,
    EzFormItem,
    EzButton,
    EzTree,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    fileId: {
      type: String,
      default: null,
    },
    folderId: {
      type: String,
      default: null,
    },
    isPopUp: {
      type: Boolean,
      default: true,
    },
    userId: {
      type: String,
      require: true,
    },
  },
  data: () => ({
    isOpenRemoveDialog: false,
    isOpenRenameDialog: false,
    isOpenShareDialog: false,
    isOpenMoveDialog: false,
    moveForlderTarget: null,
    isCopied: false,
    timeoutId: null,
    isInvalidEmail: false,
    tree: [],
    form: {
      name: null,
      email: null,
    },
  }),
  created() {
    this.form.name = this.data.name;
  },
  computed: {
    treeOptions,
    name() {
      const { data } = this;
      const { source } = data;
      return `${data.name}${source.ext ? `.${source.ext}` : ''}`;
    },
    preview() {
      if (this.isSvg) {
        const { data } = this;
        return data.url;
      }

      const { previews = [] } = this.data;
      if (previews.length) {
        const p240 = previews.find((_) => _.resolution === '240');
        return p240 ? p240.url : previews[0].url;
      }

      return null;
    },
    isSelected() {
      return this.fileId === this.data.id;
    },
    isProgressPreview() {
      return !this.preview && this.isImage && !this.isSvg;
    },
    isSvg() {
      const { source } = this.data;
      return source.ext === 'svg';
    },
    isImage() {
      const { source } = this.data;
      return EXT_IMAGE.includes(source.ext);
    },
  },
  methods: {
    onView() {
      window.open(this.data.url, '_blank');
    },
    onDownload() {
      const { name, url, source } = this.data;
      const a = document.createElement('a');
      a.href = `${url}?download=1`;
      a.target = '_blank';
      a.download = `${name}.${source.ext}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    onHandle() {
      if (this.isPopUp) {
        const { id } = this.data;
        this.$emit('handle', id);
      }
    },
    // Remove
    onToggleRemoveDialog() {
      this.isOpenRemoveDialog = !this.isOpenRemoveDialog;
    },
    onConfirmRemove() {
      this.$emit('remove', this.data.id);
      this.onToggleRemoveDialog();
    },
    // Rename
    onToggleRenameDialog() {
      this.isOpenRenameDialog = !this.isOpenRenameDialog;
    },
    onConfirmRename() {
      if (this.form.name !== this.data.name) {
        this.$emit('rename', {
          id: this.data.id,
          name: this.form.name,
        });
      }

      this.onToggleRenameDialog();
    },
    // Share
    onToggleShareDialog() {
      this.isOpenShareDialog = !this.isOpenShareDialog;
    },
    onConfirmSend() {
      const { email } = this.form;

      if (!email || !email.length) {
        this.isInvalidEmail = true;
      } else {
        this.$emit('send', { email, link: this.data.url });
        this.onToggleShareDialog();
      }
    },
    onCopyShareLink() {
      this.$refs.link.$el.getElementsByTagName('input')[0].select();
      document.execCommand('copy');
      this.isCopied = true;

      if (!this.timeoutId) {
        this.timeoutId = setTimeout(() => {
          this.isCopied = false;
          clearTimeout(this.timeoutId);
        }, 2500);
      }
    },
    // Move
    async onToggleMoveDialog() {
      await Api.getTree.call(this, this.userId);
      this.isOpenMoveDialog = !this.isOpenMoveDialog;
    },
    onConfirmMove() {
      if (this.moveForlderTarget !== this.folderId) {
        this.$emit('move', {
          file: this.data.id,
          target: this.moveForlderTarget,
        });
        this.onToggleMoveDialog();
      }
    },
    async onSelectMoveFolder({ id, action }) {
      this.moveForlderTarget = action ? id : null;
    },
    // Clone
    onClone() {
      this.$emit('clone', this.data.id);
    },
    onDismiss() {
      this.$emit('dismiss', this.data.id);
    },
    onReload() {
      this.$emit('reload', this.data.id);
    },
  },
};
</script>
