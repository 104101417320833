<template>
  <div>
    <ez-sub-header class="cloud-subheader mt-3">
      <template slot="breadcrumbs">
        <ez-breadcrumbs ref="nav">
          <ez-link
            class="breadcrumbs__item"
            v-text="$t('_start_breadcrumb')"
            @click.prevent="onHandleFolder({})"
            :class="breadcrumbs.length === 0 ? 'last' : ''"
          />

          <div class="breadcrumbs__item" v-if="isOversize">
            <ez-dropdown class="breadcrumbs__show-more">
              <ez-button
                type="light"
                size="small"
                round
                icon="ellipsis-h"
              />
              <ez-dropdown-menu slot="menu">
                <ez-dropdown-item
                  icon="link"
                  v-for="item in breadcrumbsOversize"
                  :key="item.name"
                  @click.prevent="toBreadcrumb(item.value, item.name)"
                >
                  {{ item.name }}
                </ez-dropdown-item>
              </ez-dropdown-menu>
            </ez-dropdown>
          </div>

          <div
            class="breadcrumbs__item"
            v-for="(item, index) in breadcrumbsDefault"
            :key="index"
            :class="breadcrumbsDefault.length - 1 == index ? 'last' : ''"
          >
            <ez-link
              v-text="item.name"
              @click.prevent="toBreadcrumb(item.value, item.name)"
            />

            <template v-if="breadcrumbsDefault.length - 1 == index && canActionFolder">
              <ez-dropdown
              class="breadcrumbs-action-menu"
              direction="right"
            >
              <ez-button
                class="button button--text button--xsmall is-round is-icon"
                icon="caret-down"
                :text="true"
              />
              <ez-dropdown-menu slot="menu">
                <ez-dropdown-item
                  v-if="canCreateFolder"
                  icon="plus"
                  @click="toggleFolderDialog"
                >
                  {{ $t('_create') }}
                </ez-dropdown-item>
                <ez-dropdown-item
                  v-if="!isRoot && canEditFolder"
                  icon="bold"
                  @click.stop="$refs.dialogs.onToggleRenameDialog"
                >
                  {{ $t('_rename') }}
                </ez-dropdown-item>
                <ez-dropdown-item
                  v-if="!isRoot && canEditFolder"
                  icon="arrows"
                  @click.stop="$refs.dialogs.onToggleMoveDialog"
                >
                  {{ $t('_move') }}
                </ez-dropdown-item>
                <ez-dropdown-item
                  v-if="!isRoot && canDeleteFolder"
                  type="danger"
                  iconColor="#ee5253"
                  icon="trash"
                  @click.stop="$refs.dialogs.onToggleRemoveDialog"
                >
                  {{ $t('_delete') }}
                </ez-dropdown-item>
              </ez-dropdown-menu>
            </ez-dropdown>
            </template>
          </div>
        </ez-breadcrumbs>
      </template>
      <template slot="controls">
        <ez-button-group justify="end">
          <ez-button
            v-if="canCreateFolder"
            icon="plus"
            @click="toggleFolderDialog"
          >{{ $t('_add_folder') }}</ez-button>
          <input-file
            v-if="canCreateFile"
            ref="file"
            :allow-types="allowTypes"
            @submit="onSubmitFiles"
          />
        </ez-button-group>
      </template>
    </ez-sub-header>

    <div class="content">
      <ez-row v-if="!isEmpty">
        <ez-col>
          <ez-input
            class="files-search-field"
            type="search"
            v-model="filter.query"
            @input="onSearch"
            :placeholder="$t('_placeholder_search_file')"
          ></ez-input>
        </ez-col>
      </ez-row>

      <ez-row
        v-if="!isEmpty"
        class="mt-4 pb-3"
        justify="between"
        gutter="20"
      >
        <ez-col
          size="auto"
        >
          <div class="cards__list-title">{{ $t('_file_types') }}</div>
          <div class="d-flex">
            <ez-filter-control
              :filters="extOptions"
              :all-text="$t('_explorer_all')"
              @change="onChangeExtFilter"
            />

            <img width="26px" class="ml-3" src="./assets/loading.svg" v-if="isExtFilterLoading" />
          </div>
        </ez-col>
        <ez-col size="8">
          <div class="cards__list-title">{{ $t('_sort') }}</div>
          <ez-select
            :value="filter.sortBy"
            :options="sortOptions"
            item-label="name"
            track-by="value"
            :hide-selected="false"
            @input="onHandleSort"
          />
        </ez-col>
      </ez-row>

      <new-folder
        @submit="onSubmitFolder"
        @close="toggleFolderDialog"
        :is-opened="canCreateFolder && isOpenedFolderDialog"
      />

      <ez-drop-area
        :text="$t('_drop_files_here')"
        :allow-types="allowTypes"
        :drop="canCreateFile"
        @drop="onSubmitFiles"
        @fails="onAddFailFiles"
      >
        <template slot="icon">
          <!-- eslint-disable -->
          <svg
            width="140"
            height="163"
            viewBox="0 0 140 163"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M74.7994 38.9823C69.8792 41.823 67.3481 47.189 67.8694 52.4904C63.5389 49.3883 57.6262 48.8973 52.706 51.7379C47.7858 54.5786 45.2547 59.9446 45.7759 65.246C41.4455 62.1439 35.5328 61.6529 30.6126 64.4936C25.4702 67.4626 22.9591 73.1565 23.7911 78.7029C22.5815 77.1943 21.4488 75.5989 20.438 73.8481C9.66352 55.1129 16.0255 31.2374 34.6906 20.4611C53.3557 9.68474 77.2135 16.1129 88.0197 34.8298C89.0121 36.5487 89.8274 38.3274 90.5475 40.1611C86.1602 36.6673 79.9418 36.0133 74.7994 38.9823Z"
              fill="#EB6776"
            />
            <path
              d="M99.9202 94.8067L64.4053 115.311C63.1251 116.05 63.3051 116.938 63.4191 118.986C63.4191 118.986 63.5813 121.53 64.0125 124.52C67.5313 128.341 73.4347 139.824 74.5876 141.821C76.6329 145.363 80.6501 154.065 80.6501 154.065C81.3887 155.344 83.0451 155.787 84.3254 155.048C84.3254 155.048 108.82 140.25 117.811 135.278C119.504 134.344 124.697 131.74 124.697 131.74C125.977 131 126.422 129.344 125.683 128.065L109.077 99.3022L103.614 95.8227C102.373 95.2463 101.2 94.0676 99.9202 94.8067Z"
              fill="#FFE8DB"
            />
            <path
              d="M105.923 99.574C105.923 99.574 97.2157 103.687 94.3965 105.227C87.0505 109.251 65.8565 122.706 65.8565 122.706C64.4989 123.49 63.9953 125.218 64.7873 126.59C64.7873 126.59 69.7442 134.411 70.7058 136.076C73.4023 140.747 80.5806 153.944 80.5806 153.944C81.3537 155.284 83.0875 155.763 84.4782 154.96C84.4782 154.96 108.594 140.601 116.906 135.803C118.594 134.828 124.545 131.828 124.545 131.828C125.902 131.044 126.406 129.316 125.614 127.944L109.821 100.59C109.014 99.2698 107.281 98.7902 105.923 99.574Z"
              fill="#F4D0BB"
            />
            <path
              d="M78.5038 97.6469L42.7819 63.5792C43.8762 64.0085 44.8937 64.5246 45.8393 65.2094C45.7708 64.3577 44.9181 64.0011 44.9816 63.1581L78.1373 94.7602L67.5891 51.2515C68.8478 50.9917 67.5217 52.1818 67.901 52.4721C67.7222 50.623 67.9394 48.7999 68.4573 47.0579L80.4306 96.4071L89.2837 38.9047C90.2143 39.3436 89.7521 39.5168 90.5791 40.1428L81.8299 98.8834C81.7731 99.2982 81.5158 99.6589 81.1666 99.8605C80.8174 100.062 80.3764 100.105 79.957 99.9647L23.8862 78.648C23.7392 77.5869 23.3581 77.9006 23.4433 76.8752L78.5038 97.6469Z"
              fill="#D8C8BF"
            />
            <path
              d="M67.9012 52.4721C63.5707 49.3699 57.6581 48.8789 52.7378 51.7196C47.8176 54.5603 45.3183 59.908 45.8078 65.2277C45.8078 65.2277 26.0399 49.0958 34.6907 20.4611C63.7962 27.2548 67.9012 52.4721 67.9012 52.4721Z"
              fill="#D45463"
            />
            <path
              d="M34.6908 20.461C35.5161 19.9845 36.3782 19.5717 37.2403 19.1588C20.6752 30.4203 15.2455 52.6117 25.1228 70.3794C23.8815 72.9211 23.402 75.8294 23.823 78.6846C22.6134 77.1759 21.4807 75.5805 20.4699 73.8298C9.66368 55.1128 16.0256 31.2373 34.6908 20.461Z"
              fill="#D45463"
            />
          </svg>
          <!-- eslint-enable -->
        </template>
        <div ref="fixed" :class="{'fixed': isPopUp}">
        <folders-list
          v-if="folders.length"
          :data="folders"
          :is-pop-up="isPopUp"
          :user-id="userId"
          ref="folders"
          @handle="onHandleFolder"
          @remove="onHandleRemoveFolder"
          @rename="onHandleRenameFolder"
          @move="onHandleMoveFolder"
        />
        <files-list
          v-if="!isEmptyFiles"
          :data="files"
          :fails="failFiles"
          :file-id="fileId"
          :folder-id="folderId"
          :is-pop-up="isPopUp"
          :user-id="userId"
          @handle="onHandleFile"
          @open="onOpenFileDialog"
          @remove="onHandleRemoveFile"
          @rename="onHandleRenameFile"
          @clone="onHandleCloneFile"
          @dismiss="onHandleDismissFile"
          @reload="onHandleReloadFile"
          @move="onHandleMoveFile"
          @send="onHandleSendLink"
        />
        <ez-empty-page v-else-if="isEmpty">
          <template slot="image">
            <!-- eslint-disable -->
            <svg
              width="174"
              height="135"
              viewBox="0 0 174 135"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M72.572 120.8C16.472 122 22.472 122 13.372 122.8C4.17204 123.5 -14.328 129.2 19.572 131.4C53.372 133.6 82.272 134.6 102.572 134.5C122.872 134.4 109.272 133.2 136.072 133.9C162.872 134.6 174.472 137.1 173.572 129.9C172.672 122.7 156.672 120.3 125.872 122.2C94.972 124.1 72.572 120.8 72.572 120.8Z"
                fill="#F7DFDA"
              />
              <path
                d="M70.8287 14.1253L63.0483 2.85937C61.8092 1.05797 59.7632 0 57.5732 0H35.4998C31.8402 0 28.8721 2.94515 28.8721 6.57655V103.423C28.8721 107.055 31.8402 110 35.4998 110H143.244C146.904 110 149.872 107.055 149.872 103.423V20.7018C149.872 17.0704 146.904 14.1253 143.244 14.1253C143.244 14.1253 117.892 13.2523 101.647 13.2523C89.612 13.2523 70.8287 14.1253 70.8287 14.1253Z"
                fill="#D45463"
              />
              <path
                d="M54.8015 32.0287C49.786 32.0287 45.4297 35.4985 44.3119 40.4022L31.0424 103.404C30.2112 107.046 32.504 110 36.1438 110H143.304C146.943 110 150.583 107.046 151.414 103.404C151.414 103.404 156.954 89.6201 159.255 80.4183C162.62 66.9614 163.595 45.1625 163.595 45.1625C165.143 38.4235 160.012 32 153.105 32C153.105 32 118.372 33.7528 97.3721 33.7528C80.7471 33.7528 54.8015 32 54.8015 32V32.0287Z"
                fill="#EB6776"
              />
            </svg>
            <!-- eslint-anable -->
          </template>
          <template slot="title">
            {{ $t('_empty_title') }}
          </template>
          <template slot="description">
            {{ $t('_empty_desc') }}

            <div v-if="canCreateFile" class="text-center mt-5">
              <input-file
                :allow-types="allowTypes"
                @submit="onSubmitFiles"
              />
            </div>

          </template>
        </ez-empty-page>
        <div ref="end" />
      </div>
      </ez-drop-area>
    </div>

    <folders-action-dialogs
      ref="dialogs"
      :data="{
        id: this.folderId,
        name: this.folderName
      }"
      :user-id="userId"
      :is-pop-up="isPopUp"
      @rename="onHandleRenameFolder"
      @move="onHandleMoveFolder"
      @remove="onHandleRemoveFolder"
    />

    <ez-button-group v-if="isPopUp" class="py-3">
      <ez-button type="secondary" @click.prevent="onCancel" outline>{{ $t('_cancel') }}</ez-button>
      <ez-button type="primary" @click.prevent="onApply">{{ $t('_apply') }}</ez-button>
    </ez-button-group>
    <ModalAuth
      v-if="isExpired"
      @success="onSuccessAuth"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { v4 as uuidv4 } from 'uuid';
import { ModalAuth } from '@dmant/ez-auth-common';
import EzButton from '@dmant/ez-ui-components/src/components/button';
import EzCol from '@dmant/ez-ui-components/src/components/col';
import EzDropdownItem from '@dmant/ez-ui-components/src/components/dropdown-item';
import EzDropdownMenu from '@dmant/ez-ui-components/src/components/dropdown-menu';
import EzDropdown from '@dmant/ez-ui-components/src/components/dropdown';
import EzRow from '@dmant/ez-ui-components/src/components/row';
import EzInput from '@dmant/ez-ui-components/src/components/input';
import EzButtonGroup from '@dmant/ez-ui-components/src/components/button-group';
import EzSubHeader from '@dmant/ez-ui-components/src/components/sub-header';
import EzBreadcrumbs from '@dmant/ez-ui-components/src/components/breadcrumbs';
import EzFilterControl from '@dmant/ez-ui-components/src/components/filter-control';
import EzSelect from '@dmant/ez-ui-components/src/components/select';
import EzLink from '@dmant/ez-ui-components/src/components/link';
import EzEmptyPage from '@dmant/ez-ui-components/src/components/empty-page';
import EzDropArea from '@dmant/ez-ui-components/src/components/drop-area';
import messages from './i18n';
import { SORT } from './utils/options';
import NewFolder from './components/NewFolder.vue';
import FoldersList from './components/FoldersList.vue';
import FilesList from './components/FilesList.vue';
import InputFile from './components/InputFile.vue';
import Api from './utils/api';
import FoldersActionDialogs from './components/FoldersActionDialogs.vue';
import dropRight from 'lodash/dropRight';
import permissionsMixin from './utils/permissionsMixin';

const DEFAULT_SORT_FIELD = '-dt-create';

const defaultData = () => ({
  isLoading: false,
  isOpenedFolderDialog: false,
  isOversize: false,
  isExtFilterLoading: false,
  files: [],
  failFiles: [],
  folders: [],
  extensions: [],
  breadcrumbs: [],
  process: [],

  folderId: null,
  folderName: null,
  fileId: null,
  filter: {
    sortBy: {},
    query: null,
    ext: [],
  },

  timerId: null,

  page: 1,
  isFinished: false,
  scrollTarget: 'document',

  isExpired: false,
});

export default {
  name: 'CloudCore',
  mixins: [permissionsMixin],
  // eslint-disable-next-line vue/no-unused-components
  components: {
    EzCol,
    EzRow,
    EzInput,
    EzLink,
    EzDropdown,
    EzDropdownItem,
    EzDropdownMenu,
    EzButton,
    EzButtonGroup,
    FilesList,
    FoldersList,
    NewFolder,
    InputFile,
    EzSubHeader,
    EzBreadcrumbs,
    EzFilterControl,
    EzEmptyPage,
    EzSelect,
    FoldersActionDialogs,
    EzDropArea,
    ModalAuth,
  },
  props: {
    isPopUp: {
      type: Boolean,
      default: true,
    },
    userId: {
      type: String,
      default: null,
    },
    allowTypes: {
      type: String,
      default: null,
    },
    callback: {
      type: Function,
      default: () => ({}),
    },
  },
  data: () => defaultData(),
  computed: {
    isRoot() {
      return this.breadcrumbs.length === 0;
    },
    isEmpty() {
      const {
        isLoading, files, folders, filter,
      } = this;
      const withItem = (files.length > 0 || folders.length > 0);
      return !isLoading && !withItem && !filter.query;
    },
    extOptions() {
      const extensions = this.extensions.map((e) => ({ name: e.ext, active: false }));
      extensions.forEach((e, i) => {
        extensions[i].active = !!this.filter.ext.find((ex) => ex.name === e.name && ex.active);
      });

      return extensions;
    },
    isSelectExtOptions() {
      return this.filter.ext.some(({ active }) => active);
    },
    isEmptyFiles() {
      return !this.files.filter(({ type }) => type !== 'fail').length;
    },
    sortOptions() {
      return SORT.map((option) => {
        const name = option.name.replace(/-/gi, '_');
        return {
          ...option,
          name: this.$t(`_sort_types_${name}`),
        };
      });
    },
    breadcrumbsOversize() {
      const { breadcrumbs } = this;
      if (this.isOversize) {
        return breadcrumbs.filter((b, i) => i < breadcrumbs.length - 2);
      }
      return [];
    },
    breadcrumbsDefault() {
      const { breadcrumbs } = this;
      if (this.isOversize) {
        return [...breadcrumbs].splice(breadcrumbs.length - 2, 2);
      }

      return breadcrumbs;
    },
  },
  created() {
    // i18n translations
    Object.keys(messages).forEach((key) => {
      this.$i18n.mergeLocaleMessage(key, messages[key]);
    });

    // Find default value for fucking ez-select
    this.filter.sortBy = this.sortOptions.find((s) => s.value === DEFAULT_SORT_FIELD);

    Api.Axios.interceptors.response.use((res) => res, (error) => {
      const { status } = error.response;
      if (status === 403) {
        this.isExpired = true;
      }
      return Promise.reject(error);
    });

    this.onFetch();
  },
  mounted() {
    if (this.isPopUp) {
      this.$refs.fixed.addEventListener('scroll', this.onHandleScroll);
    } else {
      document.addEventListener('scroll', this.onHandleScroll);
    }
  },
  methods: {
    checkOversize() {
      const { nav } = this.$refs;
      const width = this.breadcrumbs.reduce((sum, item) => sum + item.width + 200, 0);
      this.isOversize = width > nav.$el.offsetWidth;
    },
    async onFetch() {
      try {
        this.isLoading = true;
        await this.getExtensions();
        await Promise.all([
          Api.getFiles.call(this),
          Api.getFolders.call(this),
        ]);
        this.failFiles = [];
      } finally {
        this.isLoading = false;
      }
    },
    async loadExtensionOrFile(condition) {
      if (condition) {
        await this.onFetch();
      } else {
        this.getExtensions();
      }
    },
    async getExtensions() {
      this.isExtFilterLoading = true;
      try {
        await Api.getExtensions.call(this);
        const extList = this.extensions.map(({ ext }) => ext);
        this.filter.ext = this.filter.ext.filter(({ name }) => extList.includes(name));
      } finally {
        this.isExtFilterLoading = false;
      }
    },
    addAndCheckUploadedExtensions(files) {
      let goOnFetch = false;
      if (this.isSelectExtOptions) {
        const { ext } = this.filter;
        const uniq = new Set();
        files.forEach((file) => file && uniq.add(file.source.ext));
        uniq.forEach((fileExt) => {
          const filterExt = ext.find(({ name }) => name === fileExt);
          if (filterExt && !filterExt.active) {
            filterExt.active = true;
            goOnFetch = true;
          } else {
            ext.push({ name: fileExt, active: true });
            goOnFetch = true;
          }
        });
      }
      return goOnFetch;
    },
    async afterUploadFiles(files) {
      const loadMore = this.addAndCheckUploadedExtensions(files);
      await this.loadExtensionOrFile(loadMore);
      this.onProcess();
    },
    async onSubmitFiles(files) {
      if (this.canCreateFile && files && files.length > 0) {
        const uploads = [];
        files.forEach((file) => {
          uploads.push(Api.uploadFile.call(this, {
            file,
            directory: this.folderId,
          }));
        });

        const downloaded = await Promise.all(uploads);
        this.afterUploadFiles(downloaded);
      }
    },
    onAddFailFiles(files) {
      files.forEach((file) => {
        this.failFiles.unshift({
          id: `fail-${uuidv4()}`,
          type: 'fail',
          fileName: file.name,
          message: 'File is not allowed',
        });
      });
    },
    async onSubmitFolder({ name }) {
      if (this.canCreateFolder) {
        const { folderId, userId } = this;
        await Api.createFolder.call(this, {
          name,
          folderId,
          userId,
        });
      }
    },
    toggleFolderDialog() {
      this.isOpenedFolderDialog = !this.isOpenedFolderDialog;
    },
    // Filter handlers
    async onChangeExtFilter(value) {
      this.page = 1;
      this.isFinished = false;
      this.filter.ext = value;
      await this.onFetch();
    },
    async onHandleSort(value) {
      this.filter.sortBy = value;
      this.page = 1;
      await this.onFetch();
    },
    onSearch() {
      this.page = 1;
      this.onFetch();
    },

    // Files handlers
    async onHandleRemoveFile(id) {
      if (this.canDeleteFile) {
        await Api.removeFile.call(this, id);
        this.loadExtensionOrFile(this.isSelectExtOptions && this.isEmptyFiles);
      }
    },
    async onHandleRenameFile(data) {
      if (this.canEditFile) {
        await Api.renameFile.call(this, data);
      }
    },
    async onHandleCloneFile(id) {
      if (this.canCreateFile) {
        await Api.cloneFile.call(this, id);
      }
    },
    async onHandleSendLink(data) {
      await Api.sendEmailLink.call(this, data);
    },
    onHandleFile(id) {
      this.fileId = this.fileId === id ? null : id;
    },
    onHandleDismissFile(id) {
      const index = this.failFiles.findIndex((f) => f.id === id);
      if (index >= 0) {
        this.failFiles.splice(index, 1);
      }
    },
    async onHandleReloadFile(id) {
      const file = this.failFiles.find((f) => f.id === id);
      const reload = await Api.uploadFile.call(this, file.meta);
      this.onHandleDismissFile(id);
      this.afterUploadFiles([reload]);
    },

    toBreadcrumb(id, name) {
      this.folderId = id;
      this.folderName = name;
      this.page = 1;

      const index = this.breadcrumbs.findIndex((b) => b.value === id);
      const breadcrumbs = this.breadcrumbs.slice(0, index + 1);

      this.breadcrumbs = breadcrumbs;
      this.checkOversize();
      this.onFetch();
    },

    // Folders handlers
    async onHandleRemoveFolder(id) {
      if (this.canDeleteFolder) {
        try {
          await Api.removeFolder.call(this, id);
          const { breadcrumbsDefault } = this
          const index = breadcrumbsDefault.findIndex(crumb => crumb.value === id);
          const breadcrumbs = dropRight(breadcrumbsDefault, index)
          this.breadcrumbs = breadcrumbs;

          if (index > 0) {
            const backFolder = breadcrumbs[breadcrumbs.length - 1]
            this.onHandleFolder({
              id: backFolder.value,
              name: backFolder.name
            });
          } else {
            this.onHandleFolder({})
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    async onHandleRenameFolder(data) {
      if (this.canEditFolder) {
        await Api.renameFolder.call(this, data);
      }
    },
    async onHandleMoveFolder(data) {
      if (this.canEditFolder) {
        try {
          await Api.moveFolder.call(this, data);
          this.breadcrumbs = data.moveCrumbs.reverse();
        } catch (e) {
          console.log(e);
        }
      }
    },
    async onHandleMoveFile(data) {
      if (this.canEditFile) {
        await Api.moveFile.call(this, data);
        this.loadExtensionOrFile(this.isSelectExtOptions && this.isEmptyFiles);
      }
    },
    onHandleFolder({ id, name }) {
      this.folderId = id || null;
      this.folderName = name || null;
      this.page = 1;
      this.isFinished = false;

      const indexBreadcrumb = this.breadcrumbs.findIndex((b) => b.value === id);

      if (id) {
        if (indexBreadcrumb === -1) {
          this.breadcrumbs.push({
            icon: 'folder',
            name,
            value: id,
            width: name.length,
          });
        }
      } else {
        this.breadcrumbs = [];
      }
      this.checkOversize();
      this.onFetch();
    },

    onOpenFileDialog() {
      this.$refs.file?.onClick();
    },
    onCancel() {
      const data = defaultData();
      Object.keys(data).forEach((key) => {
        this[key] = data[key];
      });
      this.$emit('cancel');
    },
    onApply() {
      if (this.fileId) {
        const file = this.files.find((f) => f.id === this.fileId);
        const { previews = [] } = file;
        if (previews.length) {
          const p240 = previews.find((_) => _.resolution === '240');
          file.preview = p240?.url || previews[0].url;
        }
        this.$emit('apply', file);
      }
    },
    async onHandleScroll() {
      if (this.isLoading || this.isFinished) { return; }

      const { end } = this.$refs;
      const {
        top, left, right, bottom,
      } = end.getBoundingClientRect();
      const width = (window.innerWidth || document.documentElement.clientWidth);
      const height = (window.innerHeight || document.documentElement.clientHeight);

      if (top >= 0 && left >= 0 && right <= width && bottom <= height) {
        this.page += 1;
        await Api.getFiles.call(this);
      }
    },
    onProcess() {
      if (this.timerId) { return; }
      const timerId = setInterval(async () => {
        // eslint-disable-next-line no-restricted-syntax
        for (const id of this.process) {
          // eslint-disable-next-line no-await-in-loop
          await Api.processFile.call(this, id);
        }

        if (this.process.length === 0) {
          clearInterval(timerId);
        }
      }, 2000);
    },
    onHandleRenameFolderRoot() {
      const index = this.folders.findIndex((f) => f.id === this.folderId);
      this.$refs.folders[index].folder.onToggleRenameDialog();
    },
    onSuccessAuth() {
      this.isExpired = false;
    },
  },
  destroyed() {
    clearInterval(this.timerId);

    if (this.isPopUp) {
      // eslint-disable-next-line no-unused-expressions
      this.$refs.fixed?.removeEventListener?.('scroll', this.onHandleScroll);
    } else {
      document.removeEventListener('scroll', this.onHandleScroll);
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 30px;
}

.last {
  &:before {
    display: none;
  }
}

.text-center {
  text-align: center;
}

.ez-link {
  margin-right: 0px !important;
}

// .breadcrumbs__show-more {
//   left: 0px !important;
// }

.fixed {
  overflow: auto;
  max-height: calc(90vh - 330px);
  min-height: 240px;
  overflow-x: hidden;
}

.cloud-subheader {
  ::v-deep .subheader__container {
    padding: 0;
  }
}
</style>
